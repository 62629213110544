import ApiService from '@/services/api.service'

const namespaced = true

const state = {
  categories: null
}

const getters = {
  all (state) {
    return state.categories
  }
}

const actions = {
  fetch (context) {
    ApiService.get('/categories')
      .then(response => {
        context.commit('update', response.data)
      })
  },
  fetchIfNull (context) {
    if (context.state.categories === null) {
      context.dispatch('fetch')
    }
  },
  create (context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post('/categories', data)
        .then(response => {
          context.dispatch('fetch')
          resolve(response)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  delete (context, categoryId) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`/categories/${categoryId}`)
        .then(response => {
          context.dispatch('fetch')
          resolve(response)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  update (context, data) {
    return new Promise((resolve, reject) => {
      ApiService.patch(`/categories/${data.id}`, data)
        .then(response => {
          context.dispatch('fetch')
          resolve(response)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  }
}

const mutations = {
  update (state, categories) {
    state.categories = categories
  }
}

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
