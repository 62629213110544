<template>
  <div id="app" class="d-flex align-items-stretch h-100">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  created () {
  }
}
</script>

<style>
body {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Noto Sans,Ubuntu,Droid Sans,Helvetica Neue,sans-serif;
}
</style>
