import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'

Vue.use(VueRouter)

function requireAuth (to, from, next) {
  store.dispatch('auth/checkAuth')
    .then(() => {
      next()
    })
    .catch(() => {
      next({
        name: 'login',
        query: { redirect: to.fullPath }
      })
    })
}

function requireAdminAuth (to, from, next) {
  store.dispatch('auth/checkAuth')
    .then(() => {
      if (store.getters['auth/isCurrentUserAdmin']) {
        next()
      } else {
        next({ path: '/' })
      }
    })
    .catch(() => {
      next({ path: '/' })
    })
}

function denyIfAuthenticated (to, from, next) {
  store.dispatch('auth/checkAuth')
    .then(() => { next('/app') })
    .catch(next)
}

function beforeAllRoutes (func) {
  const hook = function (to, from, next) {
    store.dispatch('layout/closeFullWidthLeftMenu')
    return func(to, from, next)
  }
  return hook
}

const adminRoutes = [
  {
    name: 'admin',
    path: 'admin',
    component: () => import('@/views/admin/Index'),
    beforeEnter: beforeAllRoutes(requireAdminAuth),
    redirect: 'admin/home',
    children: [
      {
        path: 'home',
        name: 'adminHome',
        component: () => import('@/views/admin/Home')
      },
      {
        path: 'configs',
        name: 'adminConfigsList',
        component: () => import('@/views/admin/configs/ConfigsList')
      },
      {
        path: 'configs/new',
        name: 'adminConfigsNew',
        component: () => import('@/views/admin/configs/ConfigEdit')
      },
      {
        path: 'configs/:configId',
        name: 'adminConfigsEdit',
        component: () => import('@/views/admin/configs/ConfigEdit')
      }
    ]
  }
]

const userRoutes = [
  {
    path: 'events',
    name: 'events',
    component: () => import('@/views/Events'),
    beforeEnter: beforeAllRoutes(requireAuth)
  },
  {
    path: 'categories',
    name: 'categories',
    component: () => import('@/views/Categories'),
    beforeEnter: beforeAllRoutes(requireAuth)
  }
]

const commonRoutes = [
  {
    name: 'landing',
    path: '/',
    component: () => import('@/views/Landing'),
    beforeEnter: beforeAllRoutes(denyIfAuthenticated)
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('@/views/Login'),
    beforeEnter: beforeAllRoutes(denyIfAuthenticated)
  },
  {
    name: 'logout',
    path: '/logout',
    beforeEnter: beforeAllRoutes((to, from, next) => {
      store.commit('auth/destroyAuthToken')
      next('/')
    })
  },
  {
    path: '*',
    component: () => import('@/views/NotFound')
  }
]

if (process.env.NODE_ENV !== 'production') {
  commonRoutes.push({
    name: 'debug',
    path: '/debug',
    component: () => import('@/views/Debug')
  })
}

const appRoutes = userRoutes.concat(adminRoutes)

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    {
      name: 'app',
      path: '/app',
      component: () => import('@/views/Index'),
      redirect: '/app/events',
      children: appRoutes
    }
  ].concat(commonRoutes)
})

export default router
