import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import layout from './modules/layout'
import categories from './modules/categories'
import events from './modules/events'

import adminConfigs from './modules/admin/configs'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    adminConfigs,
    layout,
    categories,
    events
  }
})
