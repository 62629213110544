import moment from 'moment-timezone'

function getMonth (month) {
  const names = {
    0: 'янв',
    1: 'фев',
    2: 'мар',
    3: 'апр',
    4: 'май',
    5: 'июн',
    6: 'июл',
    7: 'авг',
    8: 'сен',
    9: 'окт',
    10: 'ноя',
    11: 'дек'
  }
  return names[month]
}

function getDay (day) {
  return day
}

function getYear (year) {
  return year === moment().year() ? null : year
}

function formatDate (value) {
  if (!value) {
    return
  }

  const date = moment
    .tz(String(value), 'UTC')
    .tz('Europe/Moscow')

  const day = getDay(date.date())
  const month = getMonth(date.month())
  const year = getYear(date.year())

  let result = `${day} ${month}`

  if (year) {
    result = result + ` ${year}`
  }

  result = result + date.format(' HH:mm')

  return result
}

export default formatDate
