import ApiService from '@/services/api.service'

const namespaced = true

const state = {
  configs: [],
  currentConfig: null,
  firstFetch: true
}

const getters = {
  getConfigs (state) {
    return state.configs
  },
  getCurrentConfig (state) {
    return state.currentConfig
  },
  showConfigsSpinner (state) {
    return state.firstFetch
  }
}

const actions = {
  fetchConfigs (context) {
    ApiService.get('/admin/configs')
      .then(response => {
        context.commit('updateConfigs', response.data.results)
      })
  },
  fetchConfig (context, configId) {
    return ApiService.get(`/admin/configs/${configId}`)
  },
  fetchCurrentConfig (context, configId) {
    context.dispatch('fetchConfig', configId)
      .then(response => {
        context.commit('setCurrentConfig', response.data)
      })
  },
  createConfig (context, config) {
    return ApiService.post('/admin/configs', config)
  },
  updateConfig (context, updateRequest) {
    return ApiService.put(
      `/admin/configs/${updateRequest.configId}`,
      updateRequest.data
    )
  }
}

const mutations = {
  updateConfigs (state, newConfigs) {
    state.configs = newConfigs
    state.firstFetch = false
  },
  setCurrentConfig (state, newConfig) {
    state.currentConfig = newConfig
  }
}

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
