import Vue from 'vue'
import store from './store'
import router from './router'
import App from './App'
import ApiService from './services/api.service'
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import formatDate from './filters'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  faUserGraduate,
  faPencilAlt,
  faLongArrowAltLeft,
  faCheckCircle,
  faEllipsisH,
  faAngleRight,
  faAngleDown,
  faExclamationTriangle,
  faBars,
  faUser,
  faCheck
} from '@fortawesome/free-solid-svg-icons'

import VueNotifications from 'vue-notifications'
import iziToast from 'izitoast'
import 'izitoast/dist/css/iziToast.min.css'

library.add(faUserGraduate)
library.add(faPencilAlt)
library.add(faLongArrowAltLeft)
library.add(faCheckCircle)
library.add(faCheck)
library.add(faEllipsisH)
library.add(faAngleRight)
library.add(faAngleDown)
library.add(faExclamationTriangle)
library.add(faBars)
library.add(faUser)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(BootstrapVue)

Vue.filter('formatDate', formatDate)

function toast ({ title, message, type, timeout, cb }) {
  if (type === VueNotifications.types.warn) type = 'warning'
  return iziToast[type]({ title, message, timeout })
}

iziToast.settings({
  progressBar: false,
  animateInside: false,
  position: 'topRight',
  transitionIn: 'fadeInLeft'
})

const notificationOptions = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
}

Vue.use(VueNotifications, notificationOptions)

ApiService.init()

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
