import ApiService from '@/services/api.service'
import utils from '@/utils'

const namespaced = true

const state = {
  events: [],
  eventsStateToken: null,
  summary: null,
  summaryStateToken: null,
  nextPage: null,
  prevPage: null,
  categoriesByOwner: null
}

const getters = {
  getPage (state) {
    return state.events
  },
  getNextPage (state) {
    return state.nextPage
  },
  getSummary (state) {
    return state.summary
  },
  getCategoriesByOwner (state) {
    return (ownerId) => {
      if (state.categoriesByOwner !== null) {
        return state.categoriesByOwner[ownerId]
      }
      return null
    }
  }
}

const actions = {
  fetch (context, data) {
    let url = '/events'
    if (data.filter) {
      url += `?filter=${encodeURIComponent(data.filter)}`
    }

    context.commit('updateEventsStateToken', utils.uuidv4())

    ApiService.get(url, null, { 'X-Idempotency-Token': context.state.eventsStateToken })
      .then(response => {
        if (response.headers['x-idempotency-token'] === context.state.eventsStateToken) {
          context.commit('updatePaged', { append: false, data: response.data })
        }
      })
  },
  fetchNext (context) {
    if (!context.state.nextPage) return

    context.commit('updateEventsStateToken', utils.uuidv4())

    ApiService.get(context.state.nextPage, null, { 'X-Idempotency-Token': context.state.eventsStateToken })
      .then(response => {
        if (response.headers['x-idempotency-token'] === context.state.eventsStateToken) {
          context.commit('updatePaged', { append: true, data: response.data })
        }
      })
  },
  setCategory (context, data) {
    const oldEventsCategories = data.reduce((map, item) => {
      map[item.event.id] = item.oldCategory
      return map
    }, {})

    const requestData = {
      events: data.map(item => {
        context.commit('setCategory', {
          eventId: item.event.id,
          category: item.newCategory
        })
        return {
          event_id: item.event.id,
          event_version: item.event.version,
          category_id: item.newCategory.id
        }
      })
    }

    return new Promise((resolve, reject) => {
      ApiService.post('events/set-category', requestData)
        .then(response => {
          context.commit('updateEvents', response.data.updated_events)
          resolve()
        })
        .catch((err) => {
          for (const eventId in oldEventsCategories) {
            context.commit('setCategory', {
              eventId: eventId,
              category: oldEventsCategories[eventId]
            })
          }
          reject(err)
        })
    })
  },
  fetchSummary (context, data) {
    let url = '/events/summary'
    if (data.filter) {
      url += `?filter=${encodeURIComponent(data.filter)}`
    }

    context.commit('updateSummaryStateToken', utils.uuidv4())

    ApiService.get(url, null, { 'X-Idempotency-Token': context.state.summaryStateToken })
      .then(response => {
        if (response.headers['x-idempotency-token'] === context.state.summaryStateToken) {
          context.commit('updateSummary', response.data)
        }
      })
  }
}

const mutations = {
  updatePaged (state, { append, data }) {
    state.nextPage = data.next
    state.prevPage = data.previous
    if (append) {
      state.events.push(...data.results)
    } else {
      state.events = data.results
    }
    if (data.categories_by_owner) {
      state.categoriesByOwner = data.categories_by_owner
    }
  },
  setCategory (state, { eventId, category }) {
    const idx = state.events.findIndex(event => event.id === eventId)
    if (idx > -1) {
      if (category !== null) {
        state.events[idx].category = {
          id: category.id,
          title: category.title
        }
      } else {
        state.events[idx].category = null
      }
    }
  },
  updateEvents (state, updatedEvents) {
    const updatedEventsById = updatedEvents.reduce((map, event) => {
      map[event.id] = event
      return map
    }, {})

    state.events = state.events.map(
      event => updatedEventsById[event.id] === undefined
        ? event
        : updatedEventsById[event.id])
  },
  updateSummary (state, updatedSummary) {
    state.summary = updatedSummary
  },
  updateEventsStateToken (state, token) {
    state.eventsStateToken = token
  },
  updateSummaryStateToken (state, token) {
    state.summaryStateToken = token
  }
}

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
