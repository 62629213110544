import ApiService from '@/services/api.service'
import TokenService from '@/services/token.service'
import utils from '@/utils'

const namespaced = true

const state = {
  user: null,
  token: null
}

const getters = {
  isLoggedIn (state) {
    return state.token !== null && state.user !== null && TokenService.hasToken()
  },
  currentUserName (state) {
    return 'Пользователь'
    // if (state.user !== null) {
    //   return `${state.user.first_name} ${state.user.last_name}`
    // }
    // return null
  },
  getCurrentUser (state) {
    return state.user
  },
  isCurrentUserAdmin (state) {
    return state.user === null ? false : state.user.is_admin
  }
}

const actions = {
  fetchAuthToken (context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post('/auth', credentials)
        .then(response => {
          context.commit('setAuthToken', response.data.token)
          resolve()
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  fetchCurrentUser (context) {
    return new Promise((resolve, reject) => {
      ApiService.get('/users/current')
        .then(response => {
          const data = response.data
          if (data.authenticated) {
            context.commit('setCurrentUser', data.user)
            resolve()
          } else {
            context.commit('destroyAuthToken')
            /* eslint-disable prefer-promise-reject-errors */
            reject()
          }
        })
        .finally(reject)
    })
  },
  checkAuth (context) {
    return new Promise((resolve, reject) => {
      if (context.getters.isLoggedIn) {
        resolve()
      } else {
        if (TokenService.hasToken()) {
          context.commit('setAuthToken', TokenService.getToken())
        }

        const searchParams = utils.getUrlSearchParams(window.location.search)

        if (searchParams.token !== null) {
          context.commit('setAuthToken', searchParams.token)
        }

        context.dispatch('fetchCurrentUser')
          .then(resolve)
          .finally(reject)
      }
    })
  }
}

const mutations = {
  setAuthToken (state, token) {
    state.token = token
    TokenService.saveToken(token)
    ApiService.setAuthHeader(token)
  },
  setCurrentUser (state, user) {
    state.user = user
  },
  destroyAuthToken (state) {
    state.user = null
    state.token = null
    TokenService.destroyToken()
    ApiService.destroyAuthHeader()
  }
}

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
