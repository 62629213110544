const namespaced = true

const state = {
  leftLinks: [
    {
      title: 'События',
      to: '/app/events'
    },
    {
      title: 'Категории',
      to: '/app/categories'
    }
  ],
  leftMenuOpened: true,
  leftMenuFullwidthThreshold: 991.98
}

const getters = {
  getLeftLinks (state, getters) {
    return state.leftLinks.filter((link) => { return true })
  },
  isLeftMenuOpened (state) {
    return state.leftMenuOpened
  }
}

const actions = {
  toogleLeftMenu (context) {
    context.commit('setLeftMenuOpened', !context.getters.isLeftMenuOpened)
  },
  closeLeftMenu (context) {
    context.commit('setLeftMenuOpened', false)
  },
  openLeftMenu (context) {
    context.commit('setLeftMenuOpened', true)
  },
  closeFullWidthLeftMenu (context) {
    if (
      context.getters.isLeftMenuOpened &&
      window.innerWidth <= context.state.leftMenuFullwidthThreshold
    ) {
      context.dispatch('closeLeftMenu')
    }
  }
}

const mutations = {
  setLeftMenuOpened (state, isOpened) {
    state.leftMenuOpened = isOpened
  }
}

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
