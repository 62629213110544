const AUTH_TOKEN_KEY = 'auth_token'

export const getToken = () => {
  return window.localStorage.getItem(AUTH_TOKEN_KEY)
}

export const hasToken = () => {
  return getToken() !== null
}

export const saveToken = (token) => {
  window.localStorage.setItem(AUTH_TOKEN_KEY, token)
}

export const destroyToken = () => {
  window.localStorage.removeItem(AUTH_TOKEN_KEY)
}

export default { getToken, saveToken, destroyToken, hasToken }
