import { v4 as uuidv4 } from 'uuid'

function getUrlSearchParams (searchString) {
  return new Proxy(new URLSearchParams(searchString), {
    get: (searchParams, prop) => searchParams.get(prop)
  })
}

export default {
  uuidv4,
  getUrlSearchParams
}
